import ImgContent from "../imgContent"
import React from "react"
const PhotoServices = () => {
  return (
    <section
      className="elementor-element elementor-element-92d239f elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
      data-id="92d239f"
      data-element_type="section"
      data-settings='{"stretch_section":"section-stretched"}'
    >
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-element elementor-element-6f1c97b elementor-column elementor-col-100 elementor-top-column"
            data-id="6f1c97b"
            data-element_type="column"
            data-settings='{"":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-63006b3 animation elementor-widget elementor-widget--image"
                  data-id="63006b3"
                  data-element_type="widget"
                  data-settings='{"":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="image.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-image image-margin">
                      {/* <img
												width={1440}
												height={759}
												src={img1}
												className="attachment-full size-full"
												alt=""
											/> */}

                      <ImgContent pmlImg="flowct.png" className="image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PhotoServices
